<template>
  <div v-if="showTags" :style="{ height: tagsHeight }" class="tags" style="display: flex">
    <ul class="vd_ul">
      <li v-for="(item, index) in tagsList" :key="index" :class="{ active: isActive(item.path) }" class="tags-li" @dblclick="closeTags(index)">
        <router-link :to="item.path" class="tags-li-title">
          {{ item.title }}
        </router-link>
        <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
      </li>
    </ul>
    <div style="margin-left: auto">
      <el-dropdown @command="handleTags">
        <el-button size="mini" style="height: 26px; padding: 0 15px !important; margin: 3px" type="primary">
          标签选项<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item v-if="showLogList.concat(showLogEdit).includes($route.path)" command="operationLog">操作日志</el-dropdown-item>
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
          <el-dropdown-item v-show="tagsHeight === '30px'" command="open">展开</el-dropdown-item>
          <el-dropdown-item v-show="tagsHeight === '100px'" command="close">收起</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
      :direction="'rtl'"
      :modal="false"
      :visible.sync="drawer"
      append-to-body
      custom-class="drawerCustomClass"
      title="操作日志"
      @opened="openLogDrawer"
    >
      <div v-for="(item, index) in logData" :key="`log${index}`">
        <div style="padding-left: 10px; width: 100%">
          {{ `${item.oper_name}于${getDate(item.oper_time, false)}` }}
          <span style="color: red">{{ item.content.slice(0, 2) }}</span>
          {{ `了${item.content.slice(2)}${item.request_param ? '单据号' + item.request_param : ''}` }}
        </div>
        <el-divider></el-divider>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
import { openAPI } from '@api/modules/open';
import { getDate } from '@assets/js/dateUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  data() {
    return {
      tagsList: [],
      tagsHeight: '30px',
      drawer: false,
      showLogList: [
        '/requ_list',
        '/scon_list',
        '/sconorder_list',
        '/podr_list',
        '/podc_list',
        '/modr_list',
        '/modc_list',
        '/tran_list',
        '/pinv_list',
        '/ppay_list',
        '/stat_list',
        '/inspecationprod_list',
        '/inspecationmtrb_list'
      ],
      showLogEdit: [
        '/requ_edit',
        '/scon_edit',
        '/sconorder_edit',
        '/podr_edit',
        '/podc_edit',
        '/modr_edit',
        '/modc_edit',
        '/tran_edit',
        '/pinv_edit',
        '/ppay_edit',
        '/stat_edit',
        '/inspecationprod_edit',
        '/inspecationmtrb_edit'
      ],
      logData: []
    };
  },
  methods: {
    getDate,
    isActive(path) {
      return path === this.$route.fullPath;
    },
    // 关闭单个标签
    closeTags(index) {
      if (this.tagsList.length === 1 && this.tagsList[0].name === 'Home') {
      } else {
        const delItem = this.tagsList.splice(index, 1)[0];
        const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
        if (item) {
          delItem.path === this.$route.fullPath && this.$router.push(item.path);
        } else {
          this.$router.push('/');
        }
      }
    },
    // 关闭全部标签
    closeAll() {
      if (this.tagsList.length === 1 && this.tagsList[0].name === 'Home') {
      } else {
        this.tagsList = [];
        this.$router.push('/');
      }
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter(item => {
        return item.path === this.$route.fullPath;
      });
      this.tagsList = curItem;
    },
    // 设置标签
    setTags(route) {
      const isExist = this.tagsList.some(item => {
        return item.path === route.fullPath;
      });
      if (!isExist) {
        this.tagsList.unshift({
          title: route.meta.title,
          path: route.fullPath,
          name: route.matched[1].components.default.name
        });
      }

      bus.$emit('tags', this.tagsList);
    },
    handleTags(command) {
      switch (command) {
        case 'operationLog':
          this.drawer = true;
          break;
        case 'other':
          this.closeOther();
          break;
        case 'all':
          this.closeAll();
          break;
        case 'open':
          this.tagsHeight = '100px';
          break;
        case 'close':
          this.tagsHeight = '30px';
          break;
      }
    },
    openLogDrawer() {
      if (this.showLogList.includes(this.$route.path)) {
        const { perm_id } = this.$route.query;
        openAPI.getMyLogList({ perm_id }).then(({ data }) => {
          this.logData = data;
        });
      } else if (this.showLogEdit.includes(this.$route.path)) {
        const { key } = this.$route.query;
        const { perm_id, form_id } = JSON.parse(UrlEncode.decode(key));
        openAPI.getMyLog({ perm_id, form_id }).then(({ data }) => {
          this.logData = data;
        });
      }
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
    }
  },
  created() {
    this.setTags(this.$route);
    // 监听关闭当前页面的标签页
    this.$EventBus.$on('close_current_tags', () => {
      let index = this.tagsList.findIndex(x => x.path === this.$route.fullPath);
      this.closeTags(index);
    });
  }
};
</script>

<style lang="scss">
.tags {
  z-index: 10;
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: white;
  padding-right: 120px;
  box-shadow: 0 5px 10px #ddd;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  //float: left;
  margin: 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  border: 1px solid #e9eaec;
  background: white;
  padding: 0 5px 0 12px;
  //vertical-align: middle;
  color: $color-title-list;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: $color-tags-list;
}

.tags-li .active {
  color: $color-white;
}

.tags-li-title {
  float: left;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: $color-title-list;
}

.tags-li.active .tags-li-title {
  color: $color-white;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 30px;
  background: rgb(254, 254, 254);
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.vd_ul {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
::v-deep .el-button--primary {
  width: 110px;
  height: 30px;
}
::v-deep .vd_dropdown {
  width: 110px;
  height: 30px;
  margin-top: 20px;
  box-sizing: border-box;
}
</style>
