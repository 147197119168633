<template>
  <div class="wrapper">
    <!--<el-button class="msgButton" v-if="this.notificationExample.length > 0" type="primary" size="small" @click="toggleCollapse">隐藏</el-button>-->
    <div>
      <vHead></vHead>
      <vSidebar></vSidebar>
      <div class="content-box" :class="{ 'content-collapse': collapse }">
        <vTags></vTags>
        <div class="content">
          <transition name="slide-fade" mode="out-in">
            <keep-alive :include="tagsList">
              <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
          </transition>
          <el-backtop target=".content"></el-backtop>
        </div>
      </div>
      <transition name="fade">
        <div class="vd_refresh" v-show="flag">
          <el-card class="vd_card">
            <div class="vg_mb_8 vd_ca_f">版本已更新！</div>
            <div class="vg_mb_8">请先保存已填写的单据，防止丢失。</div>
            <div class="vd_ca_t">
              <el-button type="primary" size="small" @click="refreshClick">确认更新</el-button>
            </div>
          </el-card>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vSidebar from '@/components/SideMenu.vue';
import vHead from '@/components/TopHeader.vue';
import vTags from '@/components/Tags.vue';
import bus from '@/components/common/bus';
import { get } from '@api/request';
import { userAPI } from '@api/modules/user';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  data() {
    return {
      tagsList: [],
      collapse: false,
      flag: false,
      myInter: null,
      myInterTime: null,
      myInterNum: 0,
      updateType: 1,
      notificationExample: []
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  created() {
    bus.$on('collapse-content', msg => {
      this.collapse = msg;
    });

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on('tags', msg => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
    // this.notify({
    //   title: '委托打样133123213单退回提醒',
    //   children: [
    //     this.content({ msg: 'data.appr_inst_info' }),
    //     this.contentBtn({
    //       title: '查看',
    //       method: () => {}
    //     })
    //   ]
    // });
  },
  mounted() {
    this.timer();
    this.initWebSocket();
    this.contentBtn({
      title: '查看'
    });
  },
  beforeDestroy() {
    clearInterval(this.myInter);
    this.myInter = null;
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log('socket success');
    },
    // 接收消息,由后端发起
    onmessage(e) {
      let data = JSON.parse(e.data);
      if (data) {
        //只给此单据的录入人做提醒
        let userId = this.$cookies.get('userInfo').user_id;
        if (Number(data.user_id) === Number(userId)) {
          switch (data.status) {
            //审批提醒
            case 1:
              this.notify({
                title: '审批提醒',
                children: [
                  this.contentLink({
                    method: () => this.goAppr(data.appr_inst_url),
                    msg: data.appr_inst_info.replace(/<span style='color:red'>(.*?)<\/span>/g, (match, p1) => p1)
                  })
                ]
              });
              this.showJudge(data);
              break;
            //DYJ退单提醒
            case 2:
              this.notify({
                title: 'DYJ退单提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.DYJLook(data)
                  })
                ]
              });
              break;
            case 3:
              this.notify({
                title: '咨询单据提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '跳转列表',
                    method: () => this.goToInquiry(data)
                  })
                ]
              });
              //浏览器提醒
              this.showJudge(data);
              break;
            case 4:
              this.notify({
                title: '销售合同提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看详情',
                    method: () => this.goToScon(data)
                  })
                ]
              });
              break;
            case 5:
              this.notify({
                title: '外销退样提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/requ_push_list_9004')
                  })
                ]
              });
              break;
            case 6:
              this.notify({
                title: '咨询单提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看详情',
                    method: () => this.goToInquirySheet(data)
                  })
                ]
              });
              //刷新首页list数据
              bus.$emit('getRequPush9005Init');
              //浏览器提醒
              this.showJudge(data);
              break;
            case 7:
              this.notify({
                title: '委托提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.goToRequ(data)
                  })
                ]
              });
              //刷新首页委托打样提醒list数据
              bus.$emit('getRequPush9002Init');
              break;
            case 8:
              this.notify({
                title: '取消打样提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/requ_push_list_9004')
                  })
                ]
              });
              break;
            case 9:
              this.notify({
                title: '附件更新提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/requ_infompush_list_9007')
                  })
                ]
              });
              break;
            case 10:
              this.notify({
                title: '开票提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/scon_invpush_list_9006')
                  })
                ]
              });
              break;
            case 11:
              this.notify({
                title: '委托打样单退回提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/push_9008')
                  })
                ]
              });
              break;
            case 12:
              this.notify({
                title: '样品信息驳回提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () =>
                      this.jump('/requ_edit', {
                        key: UrlEncode.encode(
                          JSON.stringify({
                            perm_id: 114,
                            form_id: data.form_id
                          })
                        )
                      })
                  })
                ]
              });
              break;
            case 13:
              this.notify({
                title: '样品描述更新提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/requ_infompush_list_9007')
                  })
                ]
              });
              break;
            case 14:
              this.notify({
                title: '样品附件驳回提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () =>
                      this.jump('/requ_edit', {
                        key: UrlEncode.encode(
                          JSON.stringify({
                            perm_id: 114,
                            form_id: data.form_id
                          })
                        )
                      })
                  })
                ]
              });
              break;
            case 15:
              this.notify({
                title: '取消验货提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () => this.jump('/push_9010')
                  })
                ]
              });
              break;
            case 16:
              this.notify({
                title: '进口商品分摊明细提醒',
                children: [
                  this.content({ msg: data.appr_inst_info }),
                  this.contentBtn({
                    title: '查看',
                    method: () =>
                      this.jump('/oinvshare_edit', {
                        key: UrlEncode.encode(
                          JSON.stringify({
                            perm_id: 210,
                            form_id: data.form_id
                          })
                        )
                      })
                  })
                ]
              });
              break;
          }
          //刷新首页推送提醒数量
          bus.$emit('getPermInfo');
        }
      }
    },
    // 连接报错
    onerror() {},
    // 关闭连接
    onclose() {
      console.log('socket close');
    }
  },
  methods: {
    toggleCollapse() {
      document.querySelectorAll('.el-notification').forEach(item => item.classList.toggle('collapsed'));
    },
    /**
     * @param {Array} children VNode
     * @param {title} title 標題
     * @returns {VNode}
     */
    notify({ title = '', children = [] }) {
      let temp = this.$notify({
        duration: 0,
        title: title,
        customClass: 'customClass',
        onClose: val => {
          val.isShow = false;
          this.notificationExample = this.notificationExample.filter(x => x.isShow !== false);
        },
        message: this.$createElement(
          'div',
          {
            style: {
              display: 'flex',
              width: '272px'
            }
          },
          children
        )
      });
      this.notificationExample.push(temp);
    },
    /**
     * @param {String} msg msg
     * @param {title} title 標題
     * @returns {VNode}
     */
    content({ data = {}, msg = '' }) {
      return this.$createElement('span', data, msg);
    },
    /**
     * @param {method} method method
     * @param {String} title 標題
     * @returns {VNode}
     */
    contentBtn({ title = '', method = (method = () => {}) }) {
      return this.$createElement(
        'el-button',
        {
          style: { 'margin-left': 'auto', height: '28px', 'margin-top': 'auto' },
          attrs: { size: 'mini', type: 'primary' },
          on: {
            click: method
          }
        },
        title
      );
    },
    /**
     * @param {Function} method function
     * @param {String} msg 消息
     * @returns {VNode}
     */
    contentLink({ method = () => {}, msg = '' }) {
      return this.$createElement(
        'el-link',
        {
          attrs: { size: 'mini', type: 'primary', underline: false },
          on: {
            click: method
          }
        },
        msg
      );
    },
    //连接websocket
    initWebSocket() {
      let userId = this.$cookies.get('userInfo').user_id;
      let newUrl = process.env.VUE_APP_SOCKET_URL + userId;
      this.$connect(newUrl);
    },
    timer() {
      if (process.env.VUE_APP_ENV !== 'development') {
        this.myInter = setInterval(() => {
          this.getVersion();
          // if(this.myInterNum>=15){
          //   clearInterval(this.myInter)
          //   // clearInterval(this.myInterTime)
          // }
        }, 300000);
      }
      // this.myInterTime = setInterval(()=>{
      //   this.myInterNum++
      // },1000)
    },
    //获取版本
    getVersion() {
      get(userAPI.getVersion)
        .then(res => {
          if (res.data.code === 0) {
            if (localStorage.getItem('loaded') !== res.data.data.version + '') {
              clearInterval(this.myInter);
              this.myInter = null;
              this.flag = true;
              this.updateType = res.data.data.update_type;
              if (res.data.data.update_type === 2) {
                localStorage.setItem('loaded', res.data.data.version + '');
              }
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 确认更新
    refreshClick() {
      this.flag = false;
      if (this.updateType === 1) {
        get(userAPI.userLogout).then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo');
            this.$cookies.remove('push');
            this.jump('/login');
          }
        });
      } else if (this.updateType === 2) {
        window.location.reload();
      }
    },
    //DYJ点击查看
    DYJLook(data) {
      this.jump('/fequ_push_list_9003');
    },
    //咨询单提醒跳转
    goToInquiry(data) {
      if (data.perm_id === 174) {
        //跳转价格咨询单
        this.jump('/price_consult_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: data.perm_id,
              form_id: data.form_id
            })
          )
        });
      } else if (data.perm_id === 175) {
        //跳转订单咨询单
        this.jump('/consult_list', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: data.perm_id
            })
          )
        });
      }
    },
    goToScon(data) {
      //跳转销售合同编辑页
      this.jump('/scon_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            form_id: data.form_id
          })
        )
      });
    },
    //跳转咨询单
    goToInquirySheet(data) {
      //价格咨询单
      if (data.perm_id === 174) {
        this.jump('/price_consult_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              form_id: data.form_id
            })
          )
        });
      } else {
        //订单咨询单
        this.jump('/consult_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              form_id: data.form_id
            })
          )
        });
      }
    },
    //跳转委托打样单详情页
    goToRequ(data) {
      this.jump('/requ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            form_id: data.form_id
          })
        )
      });
    },
    //查看跳转至对应单据
    goAppr(val) {
      let newArr = val.split('?');
      let newArr2 = newArr[1].split('&');
      let prop1 = newArr2[0].split('=');
      let prop2 = newArr2[1].split('=');
      let formObj = {};
      formObj[prop1[0]] = prop1[1];
      formObj[prop2[0]] = prop2[1];
      this.jump(newArr[0], { key: UrlEncode.encode(JSON.stringify(formObj)) });
    },
    //浏览器提醒
    showJudge(data) {
      // 判断是否支持显示
      if (!('Notification' in window)) {
        alert('抱歉，此浏览器不支持桌面通知！');
      }
      // granted: 用户允许该网站发送通知 default: 默认值，用户还未选择 denied: 用户拒绝该网站发送通知
      // Notification.permission === 'granted' 可用于检测用户通知权限
      Notification.requestPermission().then(result => {
        if (result === 'denied') {
          console.log('用户拒绝');
          return;
        } else if (result === 'default') {
          console.log('用户未授权');
          return;
        }
        this.sendMesgToDesk(data);
      });
    },
    // 浏览器显示消息通知
    sendMesgToDesk(data) {
      let notification = null;
      let title = '审批提醒';
      let appr_inst_info = data.appr_inst_info;
      let str1 = appr_inst_info.replace(/<span style='color:red'>(.*?)<\/span>/g, (match, p1) => p1);
      let options = {
        tag: data.user_id, // 多条消息时tag相同只显示一条通知，需要显示多条时tag一定要不同，（谷歌每次只能显示一条，火狐可以显示多条）
        body: str1, // 通知主体
        // data: {
        //   // 可以放置任意数据，方便后续使用
        //   content: data.data,
        //   originUrl: `http://localhost:8889/#/home`
        // },
        requireInteraction: true // 不自动关闭通知 默认值为false，通知会在三四秒之后自动关闭，（谷歌有用，火狐依然会自动关闭）
      };
      notification = new Notification(title, options);
      // 事件处理
      notification.onclick = ({ currentTarget: { data } }) => {
        // notification.close() 单个通知关闭
        window.focus();
        // 默认进入系统之前打开的页面，也可以这里自定义进入的页面
        // this.goAppr(data.appr_inst_url);
      };
      notification.onshow = () => {
        console.log('通知显示了');
      };
      notification.onclose = () => {
        console.log('通知被关闭了');
      };
      notification.onerror = () => {
        console.log('遇到错误');
      };
    }
  }
};
</script>
<style>
.collapsed {
  display: none;
}

.customClass {
  top: 50px !important;
}
</style>
<style scoped lang="scss">
.msgButton {
  position: absolute;
  top: 40px;
  z-index: 99999;
  right: 355px;
}
.vd_color {
  background-color: $color-primary;
  height: 2px;
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.vd_refresh {
  width: 300px;
  position: fixed;
  right: 10px;
  top: 15%;
  z-index: 999;
}

.vd_card {
  background-color: rgb(229, 229, 229);
}

.vd_ca_f {
  font-size: 20px;
  color: rgb(255, 55, 0);
}

.vd_ca_t {
  display: flex;
  justify-content: flex-end;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>
