<template>
  <div class="vd_header">
    <el-row class="vd_header_row">
      <el-col :md="20">
        <div class="vg_font_20 vd_cpName vd_font_color" @click="collapseChage">
          <span class="vg_pointer">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
          </span>
          <span class="vg_ml_8">上海满恩ERP系统</span>
        </div>
      </el-col>
      <el-col :md="4" class="vg_align_right vd_user">
        <!-- <span v-if="userForm.dyj_user_id" class="vg_cursor_hander vd_font" @click="dyjLogin">登录打样间</span> -->
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link vg_pointer vd_font_color vd_cpName">{{ userName }}<i class="el-icon-caret-bottom"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dashboard"><i class="el-icon-s-home"></i>首页</el-dropdown-item>
            <el-dropdown-item v-if="userForm.dyj_user_id" command="dyjlogin"><i class="el-icon-thumb"></i>登录打样间 </el-dropdown-item>
            <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
import { get } from '@api/request';
import { userAPI } from '@api/modules/user';

export default {
  name: 'TopHeader',
  props: {},
  data() {
    return {
      collapse: false,
      userName: '谢凡凡',
      new: {},
      userForm: {}
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getUserName();
      this.judgeChange();
    },
    //cookie name
    getUserName() {
      if (this.$cookies.get('userInfo')) {
        this.userName = this.$cookies.get('userInfo').stff_name;
      } else {
        this.jump('/login');
      }
    },
    // 判断是否可以同步打样间
    judgeChange() {
      get(userAPI.getMyUser)
        .then(res => {
          if (res.data.code === 0) {
            this.userForm = res.data.data.form;
          }
        })
        .catch(() => {});
    },
    // 打样间登录
    dyjLogin() {
      let userForm = this.userForm;
      userForm.create_time = Date.now().toString().substring(0, 10);
      let password = userForm.user_pass;
      // let vrpKey = UrlEncode.encode(
      //   JSON.stringify({
      //     userPass: password
      //   })
      // );
      this.helper.jumpDyj(userForm, password, 106, 1);
    },
    // 获取账号
    getUserInfo(val) {
      get(userAPI.getUserById, { user_id: Number(this.$cookies.get('userInfo').user_id) })
        .then(res => {
          if (res.data.code === 0) {
            this.userForm = res.data.data.form;
          }
        })
        .catch(() => {});
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit('collapse', this.collapse);
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command === 'logout') {
        get(userAPI.userLogout).then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo');
            this.$cookies.remove('push');
            this.jump('/login');
          }
        });
      } else if (command === 'dashboard') {
        this.jump('/dashboard');
      } else if (command === 'dyjlogin') {
        this.dyjLogin();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_header {
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: 0px 53px 0px 53px;
  background-color: rgb(82 183 245);
  background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);

  .vd_header_row {
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }
}

.vd_user {
  padding-right: 48px;
}

.vd_cpName {
  display: flex;
  align-items: center;
}

.vd_font_color {
  color: $color-white;
  letter-spacing: 2px;
  padding-left: 16px;
}

.vd_font {
  color: #fff;
}

.vd_font:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
</style>
